import React from 'react';
import {
  Info,
  ExpandMore,
  LibraryBooks,
  ErrorOutline,
  GitHub,
  Groups,
  Language,
  Phone,
  WarningAmber,
  Settings,
} from '@mui/icons-material';
import {
  Tooltip,
  Typography,
  Avatar,
  Button,
  CardHeader,
  Chip,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TableCell,
  TableRow,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import { Link as RLink } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArgoCiIcon from './ArgoCiIcon';
import OpenApiIcon from './OpenApiIcon';
import { BasicTable } from './BasicTable';
import { EditableArrayField } from './EditableArrayField';
import { EditableField } from './EditableField';
import { EditableLinkField } from './EditableLinkField';
import { EditableListField } from './EditableListField';
import SlackIcon from './SlackIcon';
import DatadogIcon from './DatadogIcon';
import { cluster_argo } from '../shared/serviceHandler';
import { nFormatter, findMinMax, timeAgo } from '../shared/data-utils';
import { OnCall } from './OnCall';

function capitalize(str) {
  if (str === null || str === undefined) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}

function IngressTableRow({ label, links }) {
  return (
    <TableRow>
      <TableCell>
        Ingress <Chip label={label} size="small" />
      </TableCell>
      <TableCell>
        <List sx={{ padding: 0 }}>
          {links?.map((link, i) => (
            <ListItemButton
              style={{ padding: '2px' }}
              target="_blank"
              rel="noreferrer"
              key={link}
              href={`${link}`}
            >
              <ListItemIcon style={{ minWidth: '30px' }}>
                <Language color="primary" sx={{ width: 16, height: 16 }} />
              </ListItemIcon>
              {link.replace('https://', '')}
            </ListItemButton>
          ))}
        </List>
      </TableCell>
    </TableRow>
  );
}

function ServiceFields({ service, updateService, environment }) {
  // formatting
  if (service.oncall && !service.oncall.length) {
    service.oncall = [service.oncall];
  }

  const tierOptions = [
    { name: '1', value: 1 },
    { name: '2', value: 2 },
    { name: '3', value: 3 },
    { name: '4', value: 4 },
    { name: '5', value: 5 },
  ];

  const statusOptions = [
    { name: 'Active', value: 'Active' },
    { name: 'Experimental', value: 'Experimental' },
    { name: 'Deprecated', value: 'Deprecated' },
  ];

  // Argo Link
  let argoLink;
  if (service.k8s_cluster && service.argo) {
    argoLink = `${
      cluster_argo[service.k8s_cluster] || 'https://argo.gopuff.tech'
    }/applications/${service.argo}`;
  }
  let score_breakdown = {};
  if (service.score_breakdown) {
    score_breakdown = service.score_breakdown;
  }
  let rules = [];
  if ('breakdown' in score_breakdown) {
    rules = score_breakdown.breakdown[0].rules.sort((a, b) => {
      return b.weight - a.weight;
    });
    rules = rules.filter((rule) => {
      return rule.score_contribution == 0;
    });
  }

  let score_color = 'black';
  if (service.total_score <= 25) {
    score_color = 'red';
  } else if (service.total_score <= 50) {
    score_color = 'orange';
  } else if (service.total_score <= 75) {
    score_color = 'green';
  }

  return (
    <BasicTable>
      <TableRow>
        <TableCell>Description</TableCell>
        <TableCell>
          <EditableField
            item={service}
            canEdit={service.can_edit}
            isMultiline
            updateAction={updateService}
            fieldname="description"
            fieldvalue={service.description}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Keywords</TableCell>
        <TableCell>
          <EditableArrayField
            canEdit={service.can_edit}
            item={service}
            updateAction={updateService}
            fieldname="area_of_focus"
            fieldvalue={service?.area_of_focus}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>PagerDuty Service</TableCell>
        <TableCell>
          <EditableLinkField
            canEdit={service.can_edit}
            item={service}
            updateAction={updateService}
            fieldname="service_pagerduty"
            fieldvalue={service?.service_pagerduty}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Status</TableCell>
        <TableCell>
          <EditableListField
            canEdit={service.can_edit}
            item={service}
            listoptions={statusOptions}
            updateAction={updateService}
            fieldname="status"
            fieldvalue={service.status}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          Tier{' '}
          <Tooltip title="Click here for a definition of Tier, and their differences">
            <IconButton
              target="_blank"
              href="https://gopuff.atlassian.net/wiki/spaces/GD/pages/2901311649/Service+Tiers"
              color="primary"
              size="small"
            >
              <Info sx={{ width: 16, height: 16 }} />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>
          <EditableListField
            canEdit={service.can_edit}
            item={service}
            listoptions={tierOptions}
            updateAction={updateService}
            fieldname="tier"
            fieldvalue={service.tier}
          />
        </TableCell>
      </TableRow>
      {service.status !== 'Deprecated' && (
        <TableRow>
          <TableCell>
            On-call{' '}
            <IconButton color="success" size="small">
              <Phone sx={{ width: 16, height: 16 }} />
            </IconButton>
          </TableCell>
          <TableCell>
            {service.oncall?.map((person, idx) => (
              <OnCall key={idx} person={person} />
            ))}
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        <TableCell>Team</TableCell>
        <TableCell>
          {service.team &&
            service.team?.map((t, i) => (
              <>
                <Button
                  size="small"
                  key={i}
                  startIcon={<Groups />}
                  component={RLink}
                  to={`/team/${t}`}
                >
                  {t}
                </Button>
                <Tooltip title="Changing team from puffdirectory is not yet possible, please ask for help on #chat-engops-puffdirectory slack channel.">
                  <Button
                    style={{ fontSize: '10px' }}
                    target="_blank"
                    startIcon={<Edit />}
                    href={
                      'https://gopuff.enterprise.slack.com/archives/C0451JY020Z'
                    }
                  ></Button>
                </Tooltip>
              </>
            ))}
          {!service.team && (
            <span style={{ color: 'red' }}>
              <Link
                to={`/claim-service/${service.service_name}`}
                component={RLink}
              >
                Orphan (Claim Service)
              </Link>
            </span>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Repository</TableCell>
        <TableCell>
          <Button
            size="small"
            startIcon={<GitHub />}
            target="_blank"
            href={`https://github.com/gopuff/${
              service.repo_name?.replace('/', '/tree/main/') || ''
            }`}
          >
            {service.repo_name || 'N/A'}
          </Button>
        </TableCell>
      </TableRow>
      {service.deploy_target !== 'AKS' &&
        service.deploy_target !== 'GKE' &&
        service.deploy_target !== 'EKS' && (
          <TableRow>
            <TableCell>Deploy target</TableCell>
            <TableCell>
              <CardHeader
                style={{ padding: '0px' }}
                title={service.deploy_target || 'Unknown'}
                avatar={
                  <Avatar
                    alt={service.deploy_target}
                    src={
                      service.deploy_target
                        ? `/assets/${service.deploy_target
                            .replace('/', '_')
                            .replace(' ', '_')}.png`
                        : '/assets/unknown_deploy.png'
                    }
                    sx={{ width: 24, height: 24 }}
                  />
                }
              />
            </TableCell>
          </TableRow>
        )}
      {(service.deploy_target === 'AKS' ||
        service.deploy_target === 'GKE' ||
        service.deploy_target === 'EKS') && (
        <TableRow>
          <TableCell>
            Kubernetes{' '}
            <Tooltip title="Information about where this deployment is currently at. Please note that if this information is not set, ReleaseBot cannot properly link/find this specific release">
              <IconButton
                target="_blank"
                href="https://gopuff.atlassian.net/wiki/spaces/GD/pages/2622587461/ReleaseBot"
                color="primary"
                size="small"
              >
                <Info sx={{ width: 16, height: 16 }} />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Accordion elevation={0} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ minHeight: 25, maxHeight: 25, p: 0 }}
              >
                <CardHeader
                  style={{ padding: '0px' }}
                  title={
                    `${service.deploy_target} (${
                      service.k8s_cluster || 'not set!'
                    }) ` || 'Unknown'
                  }
                  avatar={
                    <Avatar
                      alt={service.deploy_target}
                      src={
                        service.deploy_target
                          ? `/assets/${service.deploy_target
                              .replace('/', '_')
                              .replace(' ', '_')}.png`
                          : '/assets/unknown_deploy.png'
                      }
                      sx={{ width: 24, height: 24 }}
                    />
                  }
                />{' '}
                <Typography sx={{ marginLeft: '10px' }}>
                  <strong>{service.k8s_namespace || 'ns_not_set'}</strong>/
                  {service.k8s_deployment?.[0] ?? 'deployment_not_set'}
                  <Chip
                    label={capitalize(service.k8s_type) || 'N/A'}
                    size="small"
                    sx={{ marginLeft: '10px' }}
                  />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableRow>
                  <TableCell>Target</TableCell>
                  <TableCell>
                    <CardHeader
                      style={{ padding: '0px' }}
                      title={
                        `${service.deploy_target} (${
                          service.k8s_cluster || 'not set!'
                        } cluster) ` || 'Unknown'
                      }
                      avatar={
                        <Avatar
                          alt={service.deploy_target}
                          src={
                            service.deploy_target
                              ? `/assets/${service.deploy_target
                                  .replace('/', '_')
                                  .replace(' ', '_')}.png`
                              : '/assets/unknown_deploy.png'
                          }
                          sx={{ width: 24, height: 24 }}
                        />
                      }
                    />
                  </TableCell>
                </TableRow>
                {service.argo && (
                  <TableRow>
                    <TableCell>Argo</TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        startIcon={<ArgoCiIcon />}
                        target="_blank"
                        href={argoLink}
                      >
                        {service.argo}
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>
                    <Chip
                      label={capitalize(service.k8s_type) || 'N/A'}
                      size="small"
                    />
                    {service.k8s_type === 'rollout' && (
                      <Button
                        size="small"
                        startIcon={<ArgoCiIcon />}
                        target="_blank"
                        href={`https://argo-rollouts.platform-internal.gopuff.com/rollouts/${service.k8s_namespace}`}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Namespace</TableCell>
                  <TableCell>{service.k8s_namespace || 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ borderBottom: 'none' }}>
                    {capitalize(service.k8s_type) || 'Deployment'}
                  </TableCell>
                  <TableCell sx={{ borderBottom: 'none' }}>
                    {Array.isArray(service.k8s_deployment) &&
                    service.k8s_deployment.length > 0
                      ? service.k8s_deployment.join(', ')
                      : 'not set!'}
                  </TableCell>
                </TableRow>
              </AccordionDetails>
            </Accordion>
          </TableCell>
        </TableRow>
      )}
      {service.registry && (
        <TableRow>
          <TableCell>
            Registry{' '}
            <Tooltip title="Service Platform registry containing information about this deployment/rollout">
              <IconButton color="primary" size="small">
                <LibraryBooks sx={{ width: 16, height: 16 }} />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Accordion elevation={0} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ minHeight: 25, maxHeight: 25, p: 0 }}
              >
                <Button
                  size="small"
                  key={service.registry}
                  startIcon={<GitHub />}
                  component={RLink}
                  target="_blank"
                  to={`//github.com/gopuff/${service.registry}`}
                >
                  {service.registry}
                </Button>
              </AccordionSummary>
              <AccordionDetails>
                <TableRow>
                  <TableCell>Registry</TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      key={service.registry}
                      startIcon={<GitHub />}
                      component={RLink}
                      target="_blank"
                      to={`//github.com/gopuff/${service.registry}`}
                    >
                      {service.registry}
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Admin Groups</TableCell>
                  <TableCell>
                    {service.admin_groups &&
                      service.admin_groups?.map((t, i) => (
                        <Button
                          size="small"
                          key={i}
                          startIcon={<GitHub />}
                          component={RLink}
                          target="_blank"
                          to={`//github.com/orgs/gopuff/teams/${t}`}
                        >
                          {t}
                        </Button>
                      ))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Allowed Intl Callers</TableCell>
                  <TableCell>
                    <Accordion elevation={0} disableGutters>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ minHeight: 25, maxHeight: 25, p: 0 }}
                      >
                        <Typography variant="body2">
                          {service.allowedinternalcallers?.length || 0} internal
                          callers
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <List sx={{ padding: 0 }}>
                          {service.allowedinternalcallers &&
                            service.allowedinternalcallers?.map((t, i) => (
                              <ListItemButton
                                style={{
                                  padding: '2px',
                                }}
                                target="_blank"
                                rel="noreferrer"
                                key={t}
                                href={`https://${t}`}
                              >
                                <ListItemIcon
                                  style={{
                                    minWidth: '30px',
                                  }}
                                >
                                  <Settings
                                    color="primary"
                                    sx={{
                                      width: 16,
                                      height: 16,
                                    }}
                                  />
                                </ListItemIcon>
                                {t.replace('https://', '')}
                              </ListItemButton>
                            ))}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Allowed Out. Hosts</TableCell>
                  <TableCell>
                    <Accordion elevation={0} disableGutters>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ minHeight: 25, maxHeight: 25, p: 0 }}
                      >
                        <Typography variant="body2">
                          {service.allowedoutboundhosts?.length || 0} hosts
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <List sx={{ padding: 0 }}>
                          {service.allowedoutboundhosts &&
                            service.allowedoutboundhosts?.map((t, i) => (
                              <ListItemButton
                                style={{
                                  padding: '2px',
                                }}
                                target="_blank"
                                rel="noreferrer"
                                key={t}
                                href={`https://${t}`}
                              >
                                <ListItemIcon
                                  style={{
                                    minWidth: '30px',
                                  }}
                                >
                                  <Language
                                    color="primary"
                                    sx={{
                                      width: 16,
                                      height: 16,
                                    }}
                                  />
                                </ListItemIcon>
                                {t.replace('https://', '')}
                              </ListItemButton>
                            ))}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                </TableRow>
              </AccordionDetails>
            </Accordion>
          </TableCell>
        </TableRow>
      )}

      {service.status !== 'Deprecated' && service.total_score && (
        <TableRow>
          <TableCell>Score</TableCell>
          <TableCell sx={{ color: `${score_color}` }}>
            {service.total_score}/100
          </TableCell>
        </TableRow>
      )}
      {service.status !== 'Deprecated' && rules.length > 0 ? (
        <TableRow>
          <TableCell>Errors & Warnings</TableCell>
          <TableCell>
            <List sx={{ padding: 0 }}>
              {rules?.map((rule, i) => (
                <ListItemText primaryTypographyProps={{ fontSize: 14 }} key={i}>
                  <ListItemIcon
                    style={{
                      top: '2px',
                      position: 'relative',
                      minWidth: '30px',
                    }}
                  >
                    <Tooltip title={`-${rule.weight * 100}`}>
                      {rule.weight > 0.15 ? (
                        <ErrorOutline
                          sx={{
                            color: 'red',
                            width: 13,
                            height: 13,
                          }}
                        />
                      ) : (
                        <WarningAmber
                          sx={{
                            color: 'orange',
                            width: 13,
                            height: 13,
                          }}
                        />
                      )}
                    </Tooltip>
                  </ListItemIcon>
                  {rule.name} is not set.
                </ListItemText>
              ))}
            </List>
          </TableCell>
        </TableRow>
      ) : null}
      {(service.ingress_prod || service.ingress_uat || service.ingress_dev) && (
        <TableRow>
          <TableCell>
            OpenAPI{' '}
            <IconButton size="small">
              <OpenApiIcon style={{ width: '20px', height: '20px' }} />
            </IconButton>
          </TableCell>
          <TableCell>
            <EditableLinkField
              canEdit={service.can_edit}
              item={service}
              updateAction={updateService}
              fieldname="openapi"
              fieldvalue={service?.openapi}
            />
          </TableCell>
        </TableRow>
      )}
      {service.ingress_prod && environment === 'prod' && (
        <IngressTableRow label="Prod" links={service.ingress_prod} />
      )}
      {service.ingress_uat && environment === 'uat' && (
        <IngressTableRow label="UAT" links={service.ingress_uat} />
      )}
      {service.ingress_dev && environment === 'dev' && (
        <IngressTableRow label="Dev" links={service.ingress_dev} />
      )}
      <TableRow>
        <TableCell>Dashboard</TableCell>
        <TableCell>
          <EditableLinkField
            canEdit={service.can_edit}
            item={service}
            updateAction={updateService}
            fieldname="dashboard"
            fieldvalue={
              service?.dashboard?.length > 70
                ? `${service.dashboard.slice(0, 70)}...`
                : (service?.dashboard ? service?.dashboard : '') || 'N/A'
            }
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Documentation</TableCell>
        <TableCell>
          <EditableLinkField
            canEdit={service.can_edit}
            item={service}
            updateAction={updateService}
            fieldname="documentation"
            fieldvalue={service?.documentation}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Tags</TableCell>
        <TableCell>
          {service.tags?.map((tag, i) => (
            <Chip key={tag} label={tag} size="small" sx={{ margin: '2px' }} />
          ))}
          {!service.tags && '(no tags)'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Created Date</TableCell>
        <TableCell>
          {service.created_time &&
            new Date(service.created_time).toLocaleString()}{' '}
          ({timeAgo(service.created_time)} ago)
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Last Modified</TableCell>
        <TableCell>
          {service.last_modified &&
            new Date(service.last_modified).toLocaleString()}{' '}
          ({timeAgo(service.last_modified)} ago)
        </TableCell>
      </TableRow>
    </BasicTable>
  );
}

export default ServiceFields;
